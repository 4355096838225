<template>
  <el-drawer
    :title="type == 'edit' ? '编辑' : '添加'"
    :visible.sync="drawer"
    direction="rtl"
    :append-to-body="true"
    size="40%"
  >
    <div style="padding: 20px">
      <el-form ref="form" :model="form" label-width="auto" :rules="rules">
        <el-form-item label="国家:" prop="areaCode">
          <el-select
            v-model="form.areaCode"
            size="small"
            placeholder="请选择国家"
            :disabled="type === 'edit'"
            filterable
          >
            <el-option
              :disabled="item.disabled"
              :label="item.countryName"
              :value="item.countryCode"
              v-for="item in filterLanguageList"
              :key="item.languageType"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          label="部署数量:"
          prop="deployNum"
          v-if="styleCode == 201"
        >
          <el-input
            v-model.trim="form.deployNum"
            placeholder="请输入部署数量"
          ></el-input>
        </el-form-item>

        <el-form-item label="客户号:" prop="kehuId">
          <el-select
            v-model="form.kehuId"
            size="small"
            placeholder="请选择客户号"
            @change="changeKehu"
          >
            <el-option
              :label="item.cusNo"
              :value="item.cusNo"
              v-for="item in projectList"
              :key="item.projectId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="渠道号:" prop="qudaoId">
          <el-select
            v-model="form.qudaoId"
            size="small"
            placeholder="请选择渠道号"
            @change="changeQudao"
          >
            <el-option
              :label="item.channelName"
              :value="item.channelName"
              v-for="item in qudaoList"
              :key="item.projectId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="项目号:" prop="xiangmuId">
          <el-select
            v-model="form.xiangmuId"
            size="small"
            placeholder="请选择项目号"
          >
            <el-option
              :disabled="item.checked"
              :label="item.projectName"
              :value="item.projectName"
              v-for="item in xiangmuList"
              :key="item.projectId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item
          label="运营商:"
          prop="operatorName"
          v-if="this.row.blockId === '06'"
        >
          <el-input v-model.trim="form.operatorName"> </el-input>
        </el-form-item> -->
        <el-form-item label="最小版本:" prop="marketId">
          <!-- <el-select v-model="form.marketId" placeholder="请选择最小版本">
            <el-option
              v-for="item in versionModule"
              :key="item.marketId"
              :label="item.versionCode"
              :value="item.versionCode"
            >
            </el-option>
          </el-select> -->
          <el-input
            v-model.trim="form.marketId"
            placeholder="请输入最小版本号"
          ></el-input>
        </el-form-item>
        <el-form-item label="最大版本:" prop="marketMax">
          <!-- <el-select v-model="form.marketMax" placeholder="请选择最大版本">
            <el-option
              v-for="item in versionModule"
              :key="item.marketId"
              :label="item.versionCode"
              :value="item.versionCode"
            >
            </el-option>
          </el-select> -->
          <el-input
            v-model.trim="form.marketMax"
            placeholder="请输入最大版本号"
          ></el-input>
        </el-form-item>
        <!-- <template v-if="this.row.blockId === '06'">
          <el-form-item label="预留字段1:" prop="fields1">
            <el-input v-model.trim="form.fields1"> </el-input>
          </el-form-item>
          <el-form-item label="预留字段2:" prop="fields2">
            <el-input v-model.trim="form.fields2"> </el-input>
          </el-form-item>
          <el-form-item label="预留字段3:" prop="fields3">
            <el-input v-model.trim="form.fields3"> </el-input>
          </el-form-item>
        </template> -->
        <el-form-item label="备注:" prop="description">
          <el-input
            type="textarea"
            v-model="form.description"
            rows="2"
            maxlength="300"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="请选择是否上线" prop="status">
          <el-radio-group v-model="form.status">
            <el-radio :label="1">上线</el-radio>
            <el-radio :label="2">下线</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
    </div>
    <el-row type="flex" justify="end">
      <el-button type="primary" @click="save()">确定</el-button>
      <el-button @click="drawer = false">取 消</el-button>
    </el-row>
  </el-drawer>
</template>

<script>
import {
  ozBlockConfAdd,
  ozBlockConfEdit,
  getProJectList,
} from "@/api/ozBlockConf";
import { mapGetters } from "vuex";
export default {
  props: {
    isShow: Boolean,
    row: Object,
    type: String,
    styleCode: Number,
    areaCode: Array,
    checkAllLanguage: Array,
    versionModule: Array,
  },
  computed: {
    ...mapGetters(["countryList"]),
    drawer: {
      get() {
        return this.isShow;
      },
      set(val) {
        this.$emit("update:isShow", val);
      },
    },
    filterLanguageList() {
      return this.countryList;
      // return this.countryList.map((l) => {
      //   let item = { ...l };
      //   this.checkAllLanguage.map((a) => {
      //     // this.language.map((a) => {
      //     if (a.areaCode == l.countryCode) {
      //       item.disabled = true;
      //     }
      //   });
      //   return item;
      // });
    },
  },
  watch: {
    async drawer(val) {
      if (val) {
        this.form = {
          kehuId: "",
          qudaoId: "",
          xiangmuId: "",
          fields1: "",
          fields2: "",
          fields3: "",
          operatorName: "",
          ...this.row,
        };
        this.projectList = await this.getProJectDataList({ type: 1 });
        this.qudaoList = await this.getProJectDataList({
          cusNo: this.row.kehuId,
          type: 2,
        });
        this.xiangmuList = await this.getProJectDataList({
          cusNo: this.row.cusNo,
          channelName: this.row.qudaoId,
          type: 3,
        });
      }
      if (this.type == "add") {
        this.$refs.form.resetFields();
        (this.form = {
          areaCode: "",
          deployNum: "",
          marketId: "",
          marketMax: "",
          description: "",
          status: 1,
          kehuId: "",
          qudaoId: "",
          xiangmuId: "",
        }),
          (this.projectList = await this.getProJectDataList({ type: 1 }));
      }
    },
  },
  data() {
    return {
      form: {
        areaCode: "",
        deployNum: "",
        marketId: "",
        marketMax: "",
        description: "",
        status: 1,
        kehuId: "",
        qudaoId: "",
        xiangmuId: "",
      },
      projectList: [], //项目号
      qudaoList: [], //渠道号
      xiangmuList: [], //项目号
      rules: {
        areaCode: [
          { required: true, message: "请选择国家", trigger: "change" },
        ],
        deployNum: [
          { required: true, message: "请输入部署数量", trigger: "change" },
          {
            pattern: /^\+?[1-9][0-9]*$/,
            message: "请输入大于0的正整数",
          },
        ],
        marketId: [
          { required: true, message: "请选择最小版本", trigger: "change" },
          {
            pattern: /^\+?[1-9][0-9]*$/,
            message: "请输入大于0的正整数",
          },
        ],
        description: [
          { required: true, message: "请输入备注", trigger: "blur" },
        ],
        kehuId: [{ required: true, message: "请输入客户号", trigger: "blur" }],
        qudaoId: [{ required: true, message: "请输入渠道号", trigger: "blur" }],
        xiangmuId: [
          { required: true, message: "请输入项目号", trigger: "blur" },
        ],
        marketMax: [
          { required: true, message: "请选择最大版本", trigger: "change" },
          {
            pattern: /^\+?[1-9][0-9]*$/,
            message: "请输入大于0的正整数",
          },
        ],
        status: [
          { required: true, message: "请选择是否上线", trigger: "change" },
        ],
      },
    };
  },
  created() {},
  methods: {
    async changeKehu(val) {
      this.form.qudaoId = "";
      this.form.xiangmuId = "";
      this.qudaoList = await this.getProJectDataList({
        cusNo: val == "ALL" ? "" : val,
        type: 2,
      });
    },
    async changeQudao(val) {
      this.form.xiangmuId = "";
      this.xiangmuList = await this.getProJectDataList({
        cusNo: this.form.kehuId == "ALL" ? "" : this.form.kehuId,
        channelName: val == "ALL" ? "" : val,
        type: 3,
      });
    },
    async getProJectDataList(obj) {
      let res = await getProJectList({ page: 1, size: 3000, ...obj });
      if (res.code == 0) {
        let list = res.data.list || [];
        if (list.length > 0) {
          list.unshift({
            projectId: -1,
            cusNo: "ALL",
            channelName: "ALL",
            projectName: "ALL",
          });
          if (
            this.form.kehuId == "ALL" &&
            this.form.qudaoId == "ALL" &&
            obj.type == 3
          ) {
            list.forEach((element, index) => {
              if (list[index].projectName != "ALL") {
                list[index].checked = true; // 修改符合条件的元素
              }
            });
          }
        }
        return list;
      }
    },
    save() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let obj = { ...this.form, blockId: this.row.blockId };
          if (Number(obj.marketMax) < Number(obj.marketId)) {
            this.$message.error("最大版本号不能小于最小版本号");
            return;
          }
          this.submit(obj);
        } else {
          this.$message.error("请检查输入的数据");
        }
      });
    },
    submit(obj) {
      obj.moduleType = 2;
      if (this.type === "edit") {
        ozBlockConfEdit(obj).then((res) => {
          if (res.code == 0) {
            this.drawer = false;
            this.$message.success("编辑成功");
            this.$parent.fetchList();
          }
        });
      } else {
        ozBlockConfAdd(obj).then((res) => {
          if (res.code == 0) {
            this.drawer = false;
            this.$message.success("新增成功");
            this.$parent.fetchList();
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
