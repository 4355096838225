<template>
  <div class="config-detail-container">
    <el-row>
      <el-button
        v-has="'detailAdd'"
        size="small"
        type="primary"
        icon="el-icon-search"
        @click="addBtn()"
        >添加</el-button
      >
    </el-row>
    <el-row :gutter="6">
      <el-col :span="24">
        <el-form :inline="true" label-suffix=":">
          <el-form-item label="客户号" class="demo-form-inline headLabel">
            <el-select
              v-model="queryModule.kehuId"
              size="small"
              placeholder="请选择客户号"
              @change="changeKehu"
            >
              <el-option
                :label="item.cusNo"
                :value="item.cusNo"
                v-for="item in projectList"
                :key="item.projectId"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="渠道号" class="demo-form-inline headLabel">
            <el-select
              v-model="queryModule.channelName"
              size="small"
              placeholder="请选择渠道号"
              @change="changeQudao"
            >
              <el-option
                :label="item.channelName"
                :value="item.channelName"
                v-for="item in qudaoList"
                :key="item.projectId"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="项目号" class="demo-form-inline headLabel">
            <el-select
              v-model="queryModule.xiangmuId"
              size="small"
              placeholder="请选择项目号"
            >
              <el-option
                :label="item.projectName"
                :value="item.projectName"
                v-for="item in xiangmuList"
                :key="item.projectId"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="是否上线" class="demo-form-inline headLabel">
            <el-select
              v-model="queryModule.status"
              size="small"
              placeholder="请选择是否上线"
              clearable
            >
              <el-option
                v-for="item in onlineList"
                :key="item.key"
                :label="item.value"
                :value="item.key"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="国家" class="demo-form-inline">
            <el-select
              v-model="queryModule.areaCode"
              size="small"
              placeholder="请选择国家"
              clearable
              filterable
            >
              <el-option
                v-for="item in countryList"
                :key="item.languageType"
                :label="item.countryName"
                :value="item.countryCode"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="最小版本" class="demo-form-inline">
            <el-input
              v-model.trim="queryModule.marketId"
              placeholder="请输入最小版本号"
            ></el-input>
            <!-- <el-select
              v-model="queryModule.marketId"
              placeholder="请选择最小版本"
              clearable
            >
              <el-option
                v-for="item in versionModule"
                :key="item.marketId"
                :label="item.versionCode"
                :value="item.versionCode"
              >
              </el-option>
            </el-select> -->
          </el-form-item>
          <el-form-item label="最大版本" class="demo-form-inline">
            <!-- <el-select
              v-model="queryModule.marketMax"
              placeholder="请选择最大版本"
              clearable
            >
              <el-option
                v-for="item in versionModule"
                :key="item.marketId"
                :label="item.versionCode"
                :value="item.versionCode"
              >
              </el-option>
            </el-select> -->
            <el-input
              v-model.trim="queryModule.marketMax"
              placeholder="请输入最小版本号"
            ></el-input>
          </el-form-item>
          <el-button
            size="small"
            type="primary"
            icon="el-icon-search"
            @click="search()"
            >搜索</el-button
          >
        </el-form>
      </el-col>
    </el-row>
    <PageTable
      :columns="columns"
      :tableData="{ ...queryModule, list }"
      @page-change="handleChangePage"
    >
      <!-- <template slot="areaCode" slot-scope="{ row }">
        <span>{{ row.areaCode | getLanguageName(languageList) }}</span>
      </template> -->
      <template slot="status" slot-scope="{ row }">
        <span :class="{ colorRed: row.status != 1 }">{{
          row.status == 1 ? "已上线" : "已下线"
        }}</span>
      </template>

      <el-table-column fixed="right" label="操作" width="180px" align="center">
        <template slot-scope="{ row }">
          <el-button
            type="text"
            v-has="'detailEdit'"
            @click="handleOperate('edit', row)"
            >编辑</el-button
          >
          <el-button
            type="text"
            v-has="'detailConfig'"
            @click="handleOperate('config', row)"
            >部署</el-button
          >
          <el-button
            v-has="'detailDelete'"
            type="text"
            @click="handleClickDelete(row)"
          >
            <span class="colorRed">删除</span>
          </el-button>
        </template>
      </el-table-column>
    </PageTable>
    <ConfigDrawer
      :isShow.sync="internationDrawerShow"
      :type="type"
      :row="detailInternationData"
      :styleCode="detailData.style"
      :areaCode="internationList"
      :versionModule="versionModule"
      :projectList="projectList"
      :checkAllLanguage="checklanguagelist"
    />
    <div v-if="delopyDialogShow">
      <el-dialog
        center
        width="80%"
        :wrapperClosable="false"
        :append-to-body="true"
        title="部署"
        :visible.sync="delopyDialogShow"
        :close-on-click-modal="false"
      >
        <DeployDialog
          :blockConfId="detailInternationData.id"
          :blockId="detailData.blockId"
          :styleCode="detailData.style"
          :moduleCode="detailData.module"
          :tabulatioName="detailData.name"
          :deployNum="detailInternationData.deployNum"
          @closeBtn="delopyDialogShow = false"
        />
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PageTable from "@/components/PageTable.vue";
// import InternationDrawer from "./InternationDrawer.vue";
import ConfigDrawer from "./ConfigDrawer.vue";
import DeployDialog from "./DeployDialog.vue";
import { onlineList } from "@/common/constant/constant";
import {
  queryozBlockConfList,
  getMarketList,
  ozBlockDelete,
  getProJectList,
} from "@/api/ozBlockConf";
export default {
  components: { PageTable, ConfigDrawer, DeployDialog },
  // props: { isShow: Boolean, row: Object },
  // computed: {
  //   dialogVisible: {
  //     get() {
  //       return this.isShow;
  //     },
  //     set(val) {
  //       this.$emit("update:isShow", val);
  //     },
  //   },
  //   appItem() {
  //     return this.list.find((item) => item.apkId === this.activeId);
  //   },
  // },
  props: {
    detailData: {
      type: Object,
      default() {
        return {};
      },
    },
    dialogAuditVisible: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  watch: {
    dialogAuditVisible: {
      handler(newvalue) {
        if (newvalue) {
          this.fetchList({
            blockId: this.detailData.blockId,
          });
          this.queryModule = {
            blockId: this.detailData.blockId,
            areaCode: "",
            page: 1,
            size: 10,
            total: 0,
            kehuId: null,
            qudaoId: null,
            xiangmuId: null,
            moduleType: 2,
          };
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapGetters(["countryList"]),
  },
  async created() {
    this.$store.dispatch("updateCountryList");
    this.projectList = await this.getProJectDataList({ type: 1 });
    getMarketList({ page: 1, size: 1000, isDelete: 0 }).then((res) => {
      if (res.code == 0) {
        this.versionModule = res.data.list;
      }
    });
  },
  data() {
    return {
      onlineList,
      projectList: [], //项目号
      qudaoList: [], //渠道号
      xiangmuList: [], //项目号
      queryModule: {
        // blockId: "",
        areaCode: "",
        page: 1,
        size: 10,
        total: 0,
        kehuId: null,
        qudaoId: null,
        xiangmuId: null,
        moduleType: 2,
      },
      list: [],
      activeId: null,
      columns: [
        // { key: "blockId", label: "列表ID", align: "center" },
        { key: "id", label: "ID", align: "center", width: 50 },
        { key: "countryName", label: "国家", align: "center" },
        { key: "kehuId", label: "客户号", align: "center" },
        { key: "qudaoId", label: "渠道号", align: "center" },
        { key: "xiangmuId", label: "项目号", align: "center" },
        // {
        //   key: "operatorName",
        //   label: "运营商",
        //   align: "center",
        //   show: () => {
        //     return this.detailData.blockId === "06";
        //   },
        // },
        { slot: "status", label: "是否上线", align: "center" },
        { key: "marketId", label: "最小版本号", align: "center" },
        { key: "marketMax", label: "最大版本号", align: "center" },
        // {
        //   key: "fields1",
        //   label: "预留字段1",
        //   align: "center",
        //   show: () => {
        //     return this.detailData.blockId === "06";
        //   },
        // },
        // {
        //   key: "fields2",
        //   label: "预留字段2",
        //   align: "center",
        //   show: () => {
        //     return this.detailData.blockId === "06";
        //   },
        // },
        // {
        //   key: "fields3",
        //   label: "预留字段3",
        //   align: "center",
        //   show: () => {
        //     return this.detailData.blockId === "06";
        //   },
        // },
        { key: "description", label: "备注", align: "center" },
        { key: "createTime", label: "创建时间", align: "center" },
        { key: "modifyTime", label: "修改时间", align: "center" },
      ],
      internationDrawerShow: false,
      type: "add",
      detailInternationData: {},
      internationList: [],
      checklanguagelist: [],
      versionModule: [],

      delopyDialogShow: false,
    };
  },
  methods: {
    async changeKehu(val) {
      this.queryModule.channelName = "";
      this.queryModule.xiangmuId = "";
      if (val == "") {
        this.qudaoList = [];
        this.xiangmuList = [];
      }
      this.qudaoList = await this.getProJectDataList({
        cusNo: val == "ALL" ? "" : val,
        type: 2,
      });
    },
    async changeQudao(val) {
      this.queryModule.xiangmuId = "";
      if (val == "") {
        this.xiangmuList = [];
      }
      this.xiangmuList = await this.getProJectDataList({
        cusNo: this.queryModule.kehuId == "ALL" ? "" : this.queryModule.kehuId,
        channelName: val == "ALL" ? "" : val,
        type: 3,
      });
    },
    async getProJectDataList(obj) {
      let res = await getProJectList({
        page: 1,
        size: 3000,
        ...obj,
      });
      if (res.code == 0) {
        let list = res.data.list || [];
        if (list.length > 0) {
          list.unshift({
            projectId: -1,
            cusNo: "ALL",
            channelName: "ALL",
            projectName: "ALL",
          });
        }
        return list;
      }
    },
    search() {
      this.queryModule.page = 1;
      this.queryModule.total = 0;
      this.fetchList();
    },
    handleClickDelete(row) {
      const desc = `确定删除吗？`;
      this.$confirm(desc, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.toDelete(row);
        })
        .catch(() => {});
    },
    toDelete(row) {
      let obj = {
        id: row.id,
        blockId: row.blockId,
        moduleType: 2,
      };
      ozBlockDelete(obj).then((res) => {
        if (res.code == 0) {
          this.$message.success("删除成功");
          this.fetchList();
        }
      });
    },
    addBtn() {
      this.type = "add";
      this.detailInternationData = { blockId: this.detailData.blockId };
      this.internationDrawerShow = true;
    },
    handleOperate(operationType, row) {
      this.type = operationType;
      if (operationType === "edit") {
        this.detailInternationData = { ...row };
        this.internationDrawerShow = true;
      } else if (operationType === "config") {
        if (row.deployNum <= 0 && this.detailData.style == 201) {
          this.$message.error("请先配置可部署数量");
          return;
        }
        this.detailInternationData = { ...row };
        this.delopyDialogShow = true;
      }
    },
    async fetchList() {
      this.queryModule.blockId = this.detailData.blockId;
      let obj = {
        blockId: this.detailData.blockId,
        page: 1,
        size: 3000,
        moduleType: 2,
      };
      await queryozBlockConfList(obj).then((res) => {
        if (res.code == 0) {
          const { list = [] } = res.data;
          this.checklanguagelist = list;
        }
      });
      await queryozBlockConfList(this.queryModule).then((res) => {
        if (res.code == 0) {
          const { list = [], total } = res.data;
          this.list = list;
          this.queryModule.total = total;
        }
      });
    },
    handleChangePage({ page, size }) {
      this.queryModule.page = page;
      this.queryModule.size = size;
      this.fetchList();
    },

    handleCurrentChange(val) {
      this.activeId = val.apkId;
    },
  },
};
</script>

<style lang="scss" scoped>
.config-detail-container {
  height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
}

.icon {
  width: 40px;
  height: 40px;
  margin: 0 auto;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>
